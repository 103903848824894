<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex align-items-center mb-3">
          <h1 class="text-white"><i class="mdi mdi-folder-open"></i> Sucesso!</h1>
          <a @click="back()" class="btn btn-outline-white ml-auto"><i
              class="fa fa-arrow-left"></i> <span class="d-none d-md-inline"> Voltar</span></a>
        </div>
      </div>
      <div class="page-body">
        <div class="card">
          <div class="card-body p-3 p-lg-5 text-center">
            <h2>Sua carta de nomeação foi gerada com sucesso.</h2>
            <p class="my-4">Em breve você receberá a mesma no email preenchido.</p>
            <a @click="back()"
              class="btn btn-primary btn-lg px-5">Voltar</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "appointment-letter-success",
  methods: {
    back() {
      this.$router.push({
        name: "dashboard",
      });
    },
  }
}
</script>

<style></style>
