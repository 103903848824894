<template>
  <div
    class="container container-cozy"
  >
    <div class="card card-page mt-lg-3 mb-lg-5">
      <div class="card-header border-bottom">
        <h2 class="text-center uppercase">{{ action }} Usuário</h2>
      </div>
      <div class="card-body shadow">
        <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
          <b-form @submit.stop.prevent="handleSubmit(saveUser)" class="form" autocomplete="off">
          <div class="row">

            <div v-if="alertMessage" class="col-12">
              <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <validation-provider
                name="Nome"
                rules="required|max:191|fullname"
                v-slot="validationContext"
              >
                <label class="dbo-ui">Nome completo</label>
                <b-form-input
                  v-model="user.name"
                  :state="getValidationState(validationContext)"
                  placeholder="Preencha o nome completo"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <validation-provider
                name="Documento"
                rules="required"
                v-slot="validationContext"
                vid="document"
              >
                <label class="dbo-ui">{{ documentLabel }}</label>
                <b-form-input
                  v-model="user.document"
                  :state="getValidationState(validationContext)"
                  :placeholder="documentPlaceholder"
                  v-mask="documentMask"
                  :disabled="is_edit"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <validation-provider
                name="Telefone"
                rules="required"
                v-slot="validationContext"
                vid="phone"
              >
                <label class="dbo-ui">Telefone de contato</label>
                <b-form-input
                  v-model="user.phone"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  :state="getValidationState(validationContext)"
                  placeholder="Preencha o telefone principal"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <validation-provider
                  name="E-mail"
                  rules="required|email"
                  v-slot="validationContext"
                  vid="email"
                >
                <label class="dbo-ui">E-mail</label>
                <b-form-input
                  type="email"
                  v-model="user.email"
                  :state="getValidationState(validationContext)"
                  placeholder="Preencha o e-mail principal"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <validation-provider
                name="Senha"
                rules="min:6|confirmed:confirm_password"
                v-slot="validationContext"
                vid="password"
              >
                <label class="dbo-ui">Senha</label>
                <b-form-input
                  type="password"
                  v-model="user.password"
                  :state="getValidationState(validationContext)"
                  placeholder="Digite aqui a senha desejada"
                  autocomplete="off"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="col-12 col-lg-6 mb-3">
              <validation-provider
                name="Confirmação de senha"
                rules="min:6|confirmed:password"
                v-slot="validationContext"
                vid="confirm_password"
              >
                <label class="dbo-ui">Preencha a senha novamente</label>
                <b-form-input
                  type="password"
                  v-model="user.confirm_password"
                  :state="getValidationState(validationContext)"
                  placeholder="Confirme a senha"
                  autocomplete="off"
                ></b-form-input>

                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
            
            <div class="col-12 mb-3" v-if="isMaster()">
              <label class="dbo-ui">Cargo<i class="fa fa-question-circle ml-2"
                  v-b-tooltip.hover
                  title="Nível de acesso do usuário"
                ></i
              ></label>
              <validation-provider name="Cargo" rules="required">
                <div slot-scope="ProviderProps">
                  <select
                    class="form-control"
                    :class="{ 'is-invalid': ProviderProps.errors[0] }"
                    v-model="user.role_id"
                  >
                    <option
                      v-for="(index, roleName) in roles"
                      :key="index"
                      :value="index">
                      {{ roleName }}
                    </option>
                  </select>
                  <b-form-invalid-feedback>{{ ProviderProps.errors[0] }}</b-form-invalid-feedback>
                </div>
              </validation-provider>
            </div>


            <div class="col-12 col-lg-6 mb-3" v-if="isMaster()">
                <b-form-checkbox
                  id="checkbox-1"
                  class="mt-4"
                  name="checkbox-1"
                  v-model="user.has_access_panel"
                >
                  Acesso ao Painel
                </b-form-checkbox>
            </div>

            <div class="col-12 col-lg-6 mb-3" >
                <b-form-checkbox
                  id="checkbox-2"
                  class="mt-4"
                  name="checkbox-2"
                  v-model="user.has_access_policy_mail"
                >
                  Acesso ao Email de Apólice
                </b-form-checkbox>
            </div>

            <div class="col-12 col-lg-6 mb-3" v-if="isMaster()">
              <b-form-checkbox
                id="checkbox-3"
                class="mt-4"
                name="checkbox-1"
                v-model="user.has_email_verified_at"
                :disabled="disabled_email_verified_at"
              >
                E-mail Verificado
              </b-form-checkbox>
            </div>

            <div class="col-12 col-lg-6 mb-3" v-if="isMaster()">
                <b-form-checkbox
                  id="checkbox-4"
                  class="mt-4"
                  name="checkbox-4"
                  v-model="user.is_checked"
                >
                  Usuário autorizado
                </b-form-checkbox>
            </div>

            <div class="col-12 mt-3 d-flex justify-content-around">
              <button
                @click="redirect('list-users')"
                class="btn btn-light"
              >
                <i class="fa fa-arrow-left mr-1"></i>
                Voltar
              </button>
              
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="invalid"
              >
                <b-spinner v-if="loading" small></b-spinner>
                <i v-else class="fa fa-check"></i> Salvar
              </button>
            </div>
          </div>
          </b-form>
        </validation-observer>
      </div>
    </div>

    <div class="card card-page mt-0">
      <div class="card-header border-bottom">
        <h2 class="text-center uppercase">Empresas</h2>
      </div>
    
      <div class="card-body shadow">

        <b-alert variant="primary" class="text-white" v-model="showMsgSaveUser">Necessário salvar usuário</b-alert>

        <company-data :companies="companies" />

        <div class="row">
          <div class="col-12 mt-3 d-flex justify-content-around">

            <button type="button" class="button btn btn-primary"
              v-if="isMaster()"              
              @click="openNewCompanyModal()">
              <i class="fa fa-plus"></i> Nova Empresa </button>

            <button type="button" class="button btn btn-primary"
              v-if="!isMaster()"
              @click="openHolderModal()">
              <i class="fa fa-plus"></i> Adicionar Empresa </button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="modal">
      <b-modal
        id="show-modal"
        ref="showModal"
        class="mt-4"
        hide-footer
        centered>
        <template #modal-title> Adicionar Empresa </template>

        <div class="card">
          <div class="row">
            <div class="col-12 col-lg-12">              
              <validation-observer ref="observerCompany" v-slot="{ handleSubmitCompany, invalid }">
                <b-form @submit.prevent="attachNewCompany" class="form" autocomplete="off">
                <div class="row">

                  <div v-if="alertMessage" class="col-12">
                    <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
                  </div>

                  <div class="col-12 mb-3">
                    <validation-provider
                      name="Documento"
                      rules="required|min:18"
                      v-slot="validationContext"
                      vid="document"
                    >
                      <label class="dbo-ui">CNPJ</label>
                      <b-form-input
                        v-model="company.cnpj"
                        :state="getValidationState(validationContext)"
                        :placeholder="documentPlaceholder"
                        v-mask="documentMaskCompany"
                        @change="onChangeCompanyDocument"
                      ></b-form-input>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </div>

                  <div class="col-12 mb-3">
                    <validation-provider
                      name="Nome"
                      rules="required|max:191|fullname"
                      v-slot="validationContext"
                    >
                      <label class="dbo-ui">Razão Social</label>
                      <b-form-input
                        v-model="company.company_name"
                        :state="getValidationState(validationContext)"
                        placeholder="Razão Social"
                      ></b-form-input>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </div>

                  <div class="col-12 text-center my-2" v-if="loadingCompany"><b-spinner small></b-spinner> Buscando empresa</div>
                  
                  <div class="col-12 col-lg-12  d-flex justify-content-between">
                    <button
                      @click="$bvModal.hide('show-modal')"
                      class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
                    >
                      <i class="fa fa-angle-left mr-2"></i
                      ><span class="d-none d-md-inline-block"> Voltar</span>
                    </button>
                    <button
                      :disabled="invalid"
                      class="btn btn-primary btn-lg text-uppercase px-4"
                    >
                      Adicionar<i class="fa fa-angle-right ml-2"></i>
                    </button>
                  </div>
                  
                </div>
                </b-form>
              </validation-observer>

            </div>
            
          </div>
        </div>
      </b-modal>

      <b-modal
        id="show-modal-document"
        ref="showModalDocument"
        class="mt-4"
        size="lg"
        hide-footer
        centered
        :body-bg-variant="'p-3 p-lg-5'">
        <template #modal-title> Selecione um CNPJ do seu cadastro </template>

        <div class="input-group mb-4">
          <b-form-input v-model="filter" @input="debounceSearch" placeholder="Digite sua busca..."></b-form-input>
          
          <div class="input-group-append">
          <button class="btn btn-primary" @click="listCompanies()"><i class="fa fa-search"></i></button>
          </div>
        </div>

        <div class="card">
          <div class="overlay_modal" v-if="loadingModal"><b-spinner class="text-primary"></b-spinner></div>
          <div class="row">
            <div class="col-12 col-lg-12">
              
              <div class="lista">
                <div class="row row-cols-1" data-modelo="cards">
                
                  <div class="col mb-3"
                    v-for="companyFor in companiesModal"
                    :key="companyFor.cnpj"
                  >
                    <div class="card shadow border-1 h-100"
                      v-bind:class="{ 'shadow-primary border-primary': companies.find(obj => obj.cnpj == companyFor.cnpj) }"
                    >
                      <div class="card-body h-100 text-left d-flex align-items-center px-3 py-2">
                        <div class="">
                          <h6 class="mb-1 pr-3">{{ companyFor.company_name }}</h6>
                          <span class="font-14 text-primary">{{ formatCnpj(companyFor.cnpj) }}</span>
                        </div>
                        <div class="ml-auto">
                          <button data-dismiss="modal" type="button" class="button btn py-2 nowrap"
                            v-bind:class="{ 'btn-outline-primary': !companies.find(obj => obj.cnpj == companyFor.cnpj), 'btn-primary': companies.find(obj => obj.cnpj == companyFor.cnpj) }"
                            @click="attachCompany(companyFor)"
                          >
                            <i class="fa fa-check"></i><span class="d-none d-lg-inline"> Selecionar</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


            </div>
          </div>
        </div>
        
        <div class="d-flex mb-3 justify-content-center">
          <a class="btn btn-outline-primary text-uppercase font-14" v-b-toggle.collapse-new-company><i class="fa fa-plus-circle mr-1"></i> Cadastrar novo tomador</a>
        </div>

        <b-collapse id="collapse-new-company" class="position-relative">
          <div class="overlay_modal" v-if="loadingModalCnpj"><b-spinner class="text-primary"></b-spinner></div>
              <validation-observer
                ref="formCompany"
                v-slot="{ handleSubmit, invalid }">
                <b-form @submit.stop.prevent="handleSubmit(formCompany)" class="form" autocomplete="off">
                  <div class="border rounded p-3 shadow position-relative">
                    <div class="row">

                      <div v-if="alertMessage" class="col-12">
                        <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
                      </div>

                      <div class="col-12 col-lg-6 mb-3">
                        <validation-provider
                          name="cnpj"
                          rules="required"
                          v-slot="validationContext"
                          vid="cnpj">
                          <label class="dbo-ui" for="">CNPJ</label>
                          <b-form-input
                            v-model="dataForm.cnpj"
                            :state="getValidationState(validationContext)"
                            v-mask="'##.###.###/####-##'"
                            @change="onChangeCompanyCnpj"
                            ref="iptCompanyCnpj"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </validation-provider>
                      </div>

                      <div class="col-12 col-lg-6 mb-3">
                          <validation-provider
                            name="company_name"
                            rules="required"
                            v-slot="validationContext"
                            vid="company_name">
                            <label class="dbo-ui" for="">Razão Social</label>
                            <b-form-input
                              v-model="dataForm.company_name"
                              :state="getValidationState(validationContext)"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </validation-provider>
                      </div>

                      <div class="col-12 col-lg-12 d-flex justify-content-center">
                        <button :disabled="invalid" class="button btn btn-primary text-uppercase font-14"><b-spinner v-if="loading" small></b-spinner> Cadastrar</button>
                      </div>
                    </div>

                    <button type="button" class="close position-absolute" style="top: .5rem; right: .5rem;" v-b-toggle.collapse-new-company>
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                </b-form>
              </validation-observer>
        </b-collapse>
      </b-modal>
    </div>

  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { CompanyData } from '@/components/User';
import userTypes from '@/enums/userTypes';
import userService from "@/services/user";
import commonsService from "@/services/commons";
import companiesService from "@/services/company";
import authenticationService from '@/services/authentication';
import { mask } from "vue-the-mask";
import { extend } from 'vee-validate';
import commomsHelperMixin from '@/mixins/commomsHelper';
import dateFormatter from "@/mixins/dateFormatter";
import roles from "@/enums/translate/roles";

extend('fullname', value => {
  const nameParts = value.split(' ');
  return nameParts.length > 1 || 'Você deve informar seu nome completo';
});
extend('min', {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ['length']
});

export default {
  name: "Register",
  props: {
    userId: {
      type: String
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    CompanyData
  },
  directives: {
    mask
  },
  mixins: [commomsHelperMixin, dateFormatter],
  data: () => ({
    type: userTypes.NATURAL,
    loading: false,
    loadingCompany: false,
    user: { has_access_panel: false,
            has_access_policy_mail: false,
            has_email_verified_at: false,
            role_id: null,
            is_checked: false,
            isMaster: false
    },
    check_panel: false,
    is_edit: false,
    msgSaveUser: false,
    company: {},
    companies: [],
    showMsgSaveUser: false,
    disabled_email_verified_at: false,
    loadingModal: false,
    companiesModal: [],
    filter: '',
    debounce: null,
    dataForm: {},
    loadingModalCnpj: false,
    roles: roles,
    companyForm: {}
  }),
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent) => {
      if (bvEvent.componentId == 'show-modal-document') {
        this.cleanModal();
        this.listCompanies();
      }
    });
  },
  created() {
    if (this.userId) {
      this.findUser(this.userId);
    } else if (!this.isMaster()) {
      this.companies = this.getUserCompanies();
    } else if (this.$route.query.clone) {
      this.findUser(this.$route.query.clone, true);
    }
  },
  watch: {
    msgSaveUser(value){
      this.showMsgSaveUser = !!(value && this.userId);
    }
  },
  computed: {
    documentLabel() {
      return this.type == userTypes.NATURAL ? 'CPF' : 'CNPJ'
    },
    documentPlaceholder() {
      return this.type == userTypes.NATURAL ? 'Preencha o número do CPF' : 'Preencha o número do CNPJ'
    },
    documentMask() {
      return this.type == userTypes.NATURAL ? '###.###.###-##' : this.documentMaskCompany;
    },
    documentMaskCompany() {
      return '##.###.###/####-##';
    },
    action() {
      return this.userId ? "Alterar" : "Novo";
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    findUser(id, clone = false) {
      userService.getById(id).then(response => {
        if (response.data.error) {
          this.showError(response.data.message);
          return
        }
        if (!clone) {
          this.user = response.data
          this.type = response.data.type
          this.is_edit = true;
          this.msgSaveUser = false;
          this.user.isMaster = response.data.isMaster;
          if(response.data.has_access_panel) {
            this.user.has_access_panel = true
          }
          if(response.data.has_access_policy_mail){
            this.user.has_access_policy_mail = true
          }
          if(response.data.email_verified_at){
            this.user.has_email_verified_at = true
            this.disabled_email_verified_at = true
          }
          if(response.data.is_checked) {
            this.user.is_checked = true
          }
        }
        
        this.companies = response.data.companies
      }).catch((error) => {
        if (error.response.status == 403) {
            this.showError('Sem permissão de acesso.');
            this.redirect('list-users');
        } else {
          this.showError('Não foi possível recuperar o Usuário.');
        }
      })
    },
    findCnpj(cnpj) {
      this.loadingCompany = true;
      cnpj = this.traitCnpj(cnpj);
      commonsService
        .findCnpj(cnpj)
        .then((response) => {
          this.loadingCompany = false;
          if (response.data.error) {
            return;
          }
          this.company.company_name = response.data.name;
          this.company.address = response.data.address;
        })
        .finally(() =>{
          this.loadingCompany = false;
        });
    },
    storeUser() {
      this.user.companies = this.companies;
      return userService.store(this.user);
    },
    updateUser() {
      this.user.companies = this.companies;
      return userService.update(this.userId, this.user);  
    },
    saveUser(redirect = true) {
      if (this.user.has_email_verified_at) {
        this.user.has_email_verified_at = this.formatDateISO(new Date())
      }
      this.resetAlert();
      let savePromise = this.userId
        ? this.updateUser()
        : this.storeUser();
      this.loading = true;
      savePromise
        .then((response) => {
          if (response.data.errors) {
            this.showError(response.data.errors);
            return;
          }
          this.msgSaveUser = false;
          if (redirect) {
            this.$router.push({ name: "list-users" });
          }
        })
        .catch((error) => {
          this.showError("Não foi possível executar ação.");
          if (error.response && error.response.data && error.response.data.errors) {
            this.$refs.observer.setErrors(error.response.data.errors);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isMaster(){
      return authenticationService.isMaster()
    },
    redirect(routeName) {
      this.$router.push({ name: routeName });
    },
    attachCompany(company) {
      const index = this.companies.findIndex(obj => obj.cnpj === company.cnpj);
      if (index > -1) {
        this.companies.splice(index, 1);
      } else {
        this.companies.push( company );
      }
      if (this.userId) {
        this.saveUser(false);
      }
    },
    async attachNewCompany() {
      const isValid = await this.$refs.observerCompany.validate();
      if (isValid) {
        this.company.cnpj = this.traitCnpj(this.company.cnpj)
        this.companies.push( this.company );
        this.company = {};
        this.$refs.observerCompany.reset();
        this.$refs.showModal.hide();
        if (this.userId) {
          this.saveUser(false);
        }
      }
    },
    dettachCompany(company) {
      const index = this.companies.findIndex(obj => obj.cnpj === company.cnpj);
      if (index >= 0) {
        this.companies.splice(index, 1);
        if (this.userId) {
          this.saveUser(false);
        }
      }
    },
    openNewCompanyModal() {
      this.$refs.showModal.show();
    },
    openHolderModal() {
      this.$refs.showModalDocument.show();
    },
    onChangeCompanyDocument: function(value) {
      if (value.length == 18) {
        this.findCnpj(value)
      }
    },
    getUserCompanies(){
      return authenticationService.getCompanies();
    },
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.listCompanies();
      }, 500)
    },
    listCompanies() {
      this.loadingModal = true;
      companiesService
        .get({ 
          "filter": this.filter,
          "page":this.current_page,
          "per_page":this.per_page,
        })
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.companiesModal = response.data;
          this.loadingModal = false;
        }).finally(() => {
          this.loadingModal = false;
        });
    },
    formCompany() {
      this.loadingModalCnpj = true;
      companiesService
        .newCompany(this.dataForm)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.attachCompany( response.data );
          this.$refs.showModalDocument.hide();
        }).catch((error) => {
          this.$refs.formCompany.setErrors( error.response.data.errors );
        }).finally(() => {
          this.loadingModalCnpj = false;
        });
    },
    cleanModal() {
      this.filter = '';
      this.companiesModal = [];
    },
    onChangeCompanyCnpj: function(value) {
      if (value.length == 18) {
        this.findCnpjModal(value)
      }
    },
    findCnpjModal(cnpj) {
      this.loadingModalCnpj = true;
      cnpj = this.traitCnpj(cnpj);
      commonsService
        .findCnpj(cnpj)
        .then((response) => {
          if (response.data.error) {
            this.$refs.formCompany.setErrors( response.data.error );
            return;
          }
          
          this.$refs.iptCompanyCnpj.focus();
          this.dataForm.company_name = response.data.name;
          this.dataForm.address = response.data.address;
        })
        .finally(async () =>{
          await this.$refs.formCompany.validate();
          this.loadingModalCnpj = false;
        });
    },
  },
};
</script>

<style scoped>
a { cursor: pointer; }  
.lista { overflow-x: hidden; overflow-y: auto; min-height:200px; max-height: 400px; padding: 0px 10px; margin-bottom: 10px; margin-left: -8px; }
.overlay_modal { position: absolute !important; top:0px; left:0px; width:100%; height:100%; color: #FFF; align-items: center; justify-content: center; display:flex; z-index:9999; background-color: rgba(255, 255, 255, 0.4); }
</style>
