<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="d-flex align-items-center mb-3">
          <h1 class="text-white"><i class="mdi mdi-folder-open"></i> Nova carta de nomeação</h1>
          <a @click="back()" class="btn btn-outline-white ml-auto"><i
              class="fa fa-arrow-left"></i> <span class="d-none d-md-inline"> Voltar</span></a>
        </div>
      </div>

      <div v-if="alertMessage">
        <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
      </div>

      <div class="page-body">
        <div class="card">
          <div class="card-body p-3 p-lg-5">
            <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
              <b-form @submit.stop.prevent="handleSubmit(signDocument)" class="form" autocomplete="off">
              <div id="form-cadastrar" class="row form">
                <div class="col-12 col-lg-5 pr-lg-4">
                  <h6 class="form-subheader mb-3"><span>Dados da empresa</span></h6>
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <section id="section-quotation">
                        <div class="form-group mb-3">
                          <label class="dbo-ui" for="">Empresa</label>
                          <div class="input-group">
                            <div class="form-control truncate"><strong>{{ dataForm.company_name }}</strong> - {{ formatCnpj(dataForm.company_cnpj) }}</div>
                            <div class="input-group-append">
                              <button type="button" 
                                class="button btn btn-primary"
                                @click="$bvModal.show('show-modal-document')"
                              >
                                <i class="fa fa-pencil-alt"></i>
                                <span class="d-none d-md-inline-block ml-2"> Alterar</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-7 pl-lg-4 border-left">
                  <h6 class="form-subheader mb-3"><span>Dados do representante</span></h6>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <validation-provider name="nome do representante" rules="required|max:191|fullname" v-slot="validationContext"
                        vid="name">
                        <label class="dbo-ui" for="representative_name">Nome</label>
                        <b-form-input 
                          type="text" 
                          name="representative_name" 
                          id="representative_name" 
                          class="form-control"
                          placeholder="Digite o nome do representante" 
                          v-model="dataForm.representative_name"
                          :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <div class="col-md-6 mb-3">
                      <validation-provider name="cargo do representante" rules="required|max:191|min:2" v-slot="validationContext"
                        vid="name">
                        <label class="dbo-ui" for="representative_position">Cargo</label>
                        <b-form-input 
                          type="text" 
                          name="representative_position" 
                          id="representative_position" 
                          class="form-control"
                          placeholder="Digite o cargo do representante" 
                          v-model="dataForm.representative_position"
                          :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <div class="col-md-6 mb-3">
                      <validation-provider name="documento do representante" rules="required|cpf" v-slot="validationContext"
                        vid="document">
                        <label class="dbo-ui" for="representative_document">Documento</label>
                        <b-form-input 
                          v-mask="cpfMask"
                          name="representative_document" 
                          id="representative_document" 
                          class="form-control"
                          placeholder="Digite o CPF do representante" 
                          v-model="dataForm.representative_document"
                          :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <div class="col-md-6 mb-3">
                      <validation-provider name="email do representante" rules="required|email" v-slot="validationContext"
                        vid="email">
                        <label class="dbo-ui" for="representative_email">E-mail</label>
                        <b-form-input 
                          type="email" 
                          name="representative_email" 
                          id="representative_email" 
                          class="form-control"
                          placeholder="Digite o e-mail do representante" 
                          v-model="dataForm.representative_email"
                          :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div 
                  class="col-md-12 mb-3 mt-4">
                  <h6 class="form-subheader mb-3"><span>Seguradoras</span></h6>
                  <div 
                    v-if="!validInsurersList"
                    class="row mb-3 align-items-center text-center"
                  >
                    <div class="col-12 col-lg-8 mb-3 text-lg-left">
                      <p class="helper arrow-bottom mb-0">Selecione abaixo as seguradoras que deseja nomear para a FINN
                        Seguros</p>
                    </div>
                    <div class="col-12 col-lg-4 text-lg-right">
                      <button type="button" @click="markAllInsurers()"
                        class="button btn btn-sm btn-outline-secondary mr-1"><i class="fa mr-1 fa-check"></i> Selecionar
                        todas</button>
                      <button type="button" @click="clearInsurers()" class="button btn btn-sm btn-outline-secondary"><i
                          class="fa mr-1 fa-times"></i> Remover
                        todas</button>
                    </div>
                  </div>

                  <div
                      v-if="validInsurersList"
                      class=" d-flex align-items-center justify-content-center"
                    >
                    <div>
                      <div class="d-flex justify-content-center mb-2">
                        <b-spinner class="custom-spinner" label="Loading..."></b-spinner>
                      </div>
                      <div class="d-flex align-items-center">
                        <strong>Carregando seguradoras, por favor aguarde.</strong>
                      </div>
                    </div>
                  </div>

                  <div class="wrapper-grid-checkbox mx-n2 justify-content-center" id="insurers">
                    <div class="grid-checkbox-item" v-for="(value, key, index) in insurersList" :key="index">
                      <input type="checkbox" checked="" v-model="dataForm.insurers" :value="value"
                        :id="'checkbox-card-' + index" class="d-none">
                      <label class="grid-item-body align-items-center" :for="'checkbox-card-' + index">
                        <i class="far fa-square mr-1 unchecked"></i>
                        <i class="fa fa-check-square checked mr-1"></i>
                        <span class="text font-13 uppercase">{{ value }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-right">
                  <button 
                    @click="back()"
                    class="button btn btn-link text-secondary">
                    <i class="fa fa-times mr-1"></i> Cancelar
                  </button>
                  <button 
                    :disabled="invalid || validFields" 
                    class="button btn btn-primary"
                    type="submit">
                    <i class="fa fa-check mr-1"></i> Gerar carta de nomeação
                  </button>
                </div>
              </div>
            </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isLoading"
        class="overlay d-flex align-items-center justify-content-center"
      >
      <div>
        <div class="d-flex justify-content-center mb-2">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div class="d-flex align-items-center">
          <strong>Estamos gerando seu documento, por favor aguarde.</strong>
        </div>
      </div>
    </div>
    <select-company :selectedCompany="selectedCompany()" ref="modalHolder" />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import commonsService from "@/services/commons";
import docusignService from "@/services/docusign";
import { mask } from "vue-the-mask";
import { SelectCompany } from '@/components/Docusign';
import commomsHelperMixin from '@/mixins/commomsHelper';
import { extend } from 'vee-validate';
import { cpf } from 'cpf-cnpj-validator';

extend('fullname', value => {
  const nameParts = value.split(' ');
  return nameParts.length > 1 || 'Você deve informar seu nome completo';
});

extend('cpf', {
  validate: value => {
    return cpf.isValid(value);
  },
  message: 'Você deve informar um CPF válido'
});

export default {
  name: "appointment-letter",
  mixins: [commomsHelperMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    SelectCompany,
  },
  directives: {
    mask,
  },
  data: () => ({
    dataForm: {
      company_cnpj: '',
      company_name: '',
      insurers: [],
      representative_name: null,
      representative_document: null,
      representative_email: null,
      representative_position: null
    },
    insurersList: [],
    isLoading: false,
  }),
  computed: {
    cpfMask() {
      return "###.###.###-##";
    },
    validFields() {
      return this.dataForm.insurers.length == 0 || !this.dataForm.company_cnpj || !this.dataForm.company_name
    },
    validInsurersList() {
      return this.insurersList.length == 0
    }
  },
  created() {
    this.getInsurers();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    signDocument() {
      this.isLoading = true;
      docusignService
        .signDocument(this.dataForm)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          if(response.data.includes("docusign")){
            window.open(response.data,"_self");
          }
        })
        .catch(() => {
          this.showError("Não foi possível assinar o documento.");
        }).finally(() => {
          this.isLoading = false;
        });
    }, 
    getInsurers() {
      commonsService
        .appointmentLetterInsurers()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.insurersList = { ...response.data };
          this.markAllInsurers()
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as seguradoras.");
        });
    },
    clearInsurers() {
      this.dataForm.insurers = []
    },
    markAllInsurers() {
      this.clearInsurers()
      this.dataForm.insurers = Object.values(this.insurersList);
    },
    selectedCompany() {
      return {
        cnpj: this.dataForm.company_cnpj,
        company_name: this.dataForm.company_name
      }
    },
    selectCompany(company) {
      this.dataForm.company_cnpj = company.cnpj;
      this.dataForm.company_name = company.company_name;
    },
    back() {
      this.$router.push({
        name: "dashboard",
      });
    },
  }
};
</script>

<style></style>
