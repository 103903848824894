<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="row space-around mb-3 d-flex">
          <div class="col-3">
            <h1 class="text-white">Usuários</h1>
          </div>
          <div 
            class="overlay d-flex align-items-center justify-content-center" 
            v-if="loading"
            ><b-spinner small></b-spinner></div>
          <div class="col-9">
            <div class="d-flex justify-content-end">
            
              <button
                v-if="$can('user-store')"
                @click="redirect('create-edit-user')"
                class="ml-auto btn btn-outline-white mr-2"
              >
                <i class="fa fa-plus-circle"></i>
                <span class="d-none d-md-inline"> Criar usuário</span>
              </button>
              <button
                type="button"
                class="button btn btn-outline-white"
                v-b-toggle.collapse-2
              >
                <span class="mr-2">Filtros</span>
                <i class="fa fa-angle-up status-icon"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-end">
        <div class="col-12 col-lg-5 mb-2">
          <b-collapse id="collapse-2" class="w-100">
            <b-input-group>
              <b-form-input v-model="filter"></b-form-input>
              <b-input-group-prepend>
                <span class="input-group-text"
                  ><i class="fa fa-search"></i
                ></span>
              </b-input-group-prepend>
            </b-input-group>
          </b-collapse>
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>

      <div class="page-body">
        <div class="card">
          <div class="card-body p-0">
            <div class="table-responsive shadow">
              <b-table
                class="table pretty-title-gray mb-0"
                :items="items"
                :fields="fields"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(name)="data">
                  <span>{{ data.item.name }}</span>
                  <span v-if="data.item.admin" class="badge badge-secondary ml-2">Master</span>
                </template>

                <template #cell(created_at)="data">
                  <span>{{ formatDateTimeLocal(data.item.created_at) }}</span>
                </template>

                <template #cell(has_access_panel)="data">
                  <div class="ml-5 pl-2">
                    <i
                      class="fas fa-check-circle co"
                      v-if="data.item.has_access_panel"
                    ></i>
                    <i class="fas fa-ban" v-else></i>
                  </div>
                </template>

                <template #cell(has_access_policy_mail)="data">
                  <div class="ml-5 pl-2">
                    <i
                      class="fas fa-check-circle co"
                      v-if="data.item.has_access_policy_mail"
                    ></i>
                    <i class="fas fa-ban" v-else></i>
                  </div>
                </template>


                <template
                  class="table-td cursor-default text-center nowrap"
                  #cell(id)="data"
                >
                
                  <span
                    v-if="$can.any(['user-edit', 'user-update'])"
                    @click="redirect('create-edit-user', null, { clone: data.value })"
                    class="text-secondary cursor-pointer"
                    v-b-tooltip.hover
                    title="Clonar usuário"
                  >
                    <i class="far fa-copy mx-1 icon-btn"></i>
                  </span>

                  <span
                    v-if="$can.any(['user-edit', 'user-update'])"
                    @click="redirect('create-edit-user', { userId: data.value })"
                    class="text-secondary cursor-pointer"
                    v-b-tooltip.hover
                    title="Editar"
                  >
                    <i class="fas fa-edit mx-1 icon-btn"></i>
                  </span>

                  <span
                    v-if="$can.any(['user-edit', 'user-update']) && isMaster()"
                    @click="showModalDelete(data)"
                    class="text-secondary"
                    v-b-tooltip.hover
                    title="Apagar"
                  >
                    <i class="fas fa-trash mx-1 icon-btn"></i>
                  </span>
                </template>
              </b-table>
            </div>
            <nav aria-label="Paginação" class="m-3">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </nav>
          </div>
        </div>
        <div class="modal">
          <b-modal
            id="show-modal-delete"
            ref="showModalDelete"
            class="mt-4"
            size="sm"
            centered
            hide-footer
          >
            <br />
            <template #modal-title> Deseja excluir esse usuário? </template>
            <div class="mb-5 pb-2">
              <p class="my-4">Deseja excluir o usuário "{{ data.name }}" com documento {{ data.document }}?</p>
            </div>
            <div>
              <a
                @click="$bvModal.hide('show-modal-delete')"
                class="btn btn-outline-secondary"
                ><i class="fa fa-angle-left"></i> Voltar</a
              >
              <a class="btn btn-md btn-danger ml-2"
                @click="deleteUser(data.id)"
                ><i class="fas fa-times"></i> Confirmar</a
              >
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user";
import authenticationService from "@/services/authentication";
import dateFormatter from "@/mixins/dateFormatter";

export default {
  name: "list-users",
  mixins: [dateFormatter],
  data: () => ({
    perPage: 10,
    currentPage: 1,
    items: [],
    filter: null,
    data: [],
    loading: false
  }),
  created() {
    this.getUsers();
  },
  computed: {
    rows() {
      return this.items.length;
    },
    fields() {
      var columns = [
        { key: "name", label: "Nome", tdClass: "font-14", thClass: "table-pretty dbo-ui rounded-top", },
        { key: "document", label: "Documento", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
        { key: "phone", label: "Telefone", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
        { key: "email", label: "Email", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
        { key: "created_at", label: "Data de criação", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
        { key: "role_name", label: "Cargo", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
        {
          key: "has_access_panel",
          label: "Acesso ao Painel",
          tdClass: "font-14 justify-content-center",
          thClass: "table-pretty dbo-ui",
        },
              {
          key: "has_access_policy_mail",
          label: "Acesso ao Email de Apólice",
          tdClass: "font-14 justify-content-center",
          thClass: "table-pretty dbo-ui",
        },
      ];

      if (this.isMaster()) {
        columns.push({ key: "utm", label: "UTM", tdClass: "font-14", thClass: "table-pretty dbo-ui", });
      }

      columns.push({ key: "id", label: "Ação", tdClass: "column-size-share font-12", thClass: "table-pretty dbo-ui rounded-top ", });
      return columns;
    }
  },
  methods: {
    getUsers() {
      this.resetAlert();
      userService
        .users()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.items = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os usuários.");
        });
    },
    redirect(routeName, params = null, query = null) {
      this.$router.push({ name: routeName, params: params, query: query });
    },
    isMaster() {
      return authenticationService.isMaster();
    },
    showModalDelete(data) {
      this.data = data.item;
      this.$refs.showModalDelete.show();
    },
    deleteUser(user_id) {
      this.loading = true
      userService
        .destroy(user_id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.$refs.showModalDelete.hide();
          this.getUsers();
        })
        .catch(() => {
          this.showError("Não foi possível deletar o usuário.");
        }).finally(() =>{
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>
