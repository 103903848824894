<template>
  <div>
    <div v-if="errors">
      <div class="alert-custom alert-danger-custom">Suas apólices não podem ser importadas, verifique os erros abaixo e submeta novamente.</div>

      <ErrorViewer :errors="errors" :fieldLabels="fieldLabels"/>
    </div>
    <b-table v-else striped small responsive :items="policies" :fields="fields">
      <template #cell(coverage_value)="data">
        <span>{{ formatCurrency(data.value) }}</span>
      </template>
      <template #cell(validity_start)="data">
        {{ formatDateFromISO(data.value) }}
      </template>
      <template #cell(validity_expiration)="data">
        {{ formatDateFromISO(data.value) }}
      </template>
      <template #cell(insurance_premium)="data">
        <span>{{ formatCurrency(data.value) }}</span>
      </template>
      <template #cell(renewable)="data">
        <span>{{ data.value ? 'Sim' : 'Não' }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { ErrorViewer } from '@/components';
import dateFormatter from "@/mixins/dateFormatter";
import numericFormatterMixin from "@/mixins/numericFormatter";

export default {
  name: 'PreviewPoliciesStep',
  components: {
    ErrorViewer
  },
  mixins: [
    dateFormatter,
    numericFormatterMixin,
  ],
  props: {
    policies: {
      type: Array
    },
    errors: {
      type: Object
    }
  },
  computed: {
    fields() {
      return Object.keys(this.fieldLabels).map(key => {
        return {
          label: this.fieldLabels[key],
          key,
        }
      });
    }
  },
  data() {
    return {
      fieldLabels: {
        'insurance_type': 'Seguro',
        'insurance_category': 'Subtipo / Modalidade',
        'policy_number': 'Número da apólice',
        'coverage_value': 'Importância segurada',
        'insurer': 'Seguradora',
        'insurance_broker': 'Corretora',
        'policy_holder_name': 'Tomador',
        'policy_holder_document': 'CPF/CNPJ do tomador',
        'insured_name': 'Benefíciário',
        'insured_document': 'CPF/CNPJ do beneficiário',
        'validity_start': 'Início',
        'validity_expiration': 'Término',
        'insurance_premium': 'Valor do Boleto',
        'renewable': 'Renovável',
      }
    }
  }
}
</script>

<style>

</style>
